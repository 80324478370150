'use client';
import {
   useState,
   useEffect,
   ReactNode,
   useCallback,
   FunctionComponent,
   PropsWithChildren,
} from 'react';
import {
   Box,
   Text,
   Button,
   ButtonGroup,
   Stack,
   ButtonProps,
   useDisclosure,
   UnorderedList,
   ListItem,
   LinkProps,
} from '@chakra-ui/react';
import { SentryError } from '@ifixit/sentry';
import { GTag, setGA4Consent } from '@ifixit/analytics';
import { connect } from 'react-redux';
import { ppmsType, Consents, ConsentType, ShopifyAPI, ShopifyConfig } from './types';
import { useShopifyConsentScript } from './shopify-consent';
import { useTranslations } from '@ifixit/i18n';

const REJECTED = 0;
const ACCEPTED = 1;
const NOT_SEEN = -1;

declare global {
   interface Window {
      ppms?: ppmsType;
      gtag?: GTag;
      Shopify?: ShopifyAPI;
   }
}

const stateToProps = (state: {
   consentBannerState: { bannerIsOpen: boolean; detailsIsOpen: boolean };
}) => ({
   isOpenOverride: state.consentBannerState.bannerIsOpen,
   showDetails: state.consentBannerState.detailsIsOpen,
});
const dispatchToProps = (
   dispatch: ({ type, value }: { type: string; value: unknown }) => void
) => ({
   setShowBanner: (value: boolean) => dispatch({ type: 'TOGGLE_BANNER', value }),
   setShowDetails: (value: boolean) => dispatch({ type: 'TOGGLE_DETAILS', value }),
});

export const ConsentComponent = connect(
   stateToProps,
   dispatchToProps
)(
   ({
      isOpenOverride,
      showDetails,
      setShowBanner,
      setShowDetails,
      CustomLink,
      privacyPolicyUrl,
      needsConsent,
      piwikSiteId,
      shopifyConfig,
   }: {
      isOpenOverride: boolean;
      showDetails: boolean;
      setShowBanner: (val: boolean) => void;
      setShowDetails: (val: boolean) => void;
      CustomLink: FunctionComponent<PropsWithChildren<LinkProps>>;
      privacyPolicyUrl: string;
      needsConsent: boolean;
      piwikSiteId: string;
      shopifyConfig: ShopifyConfig;
   }) => {
      const { isOpen: showBanner, onOpen, onClose } = useDisclosure({ isOpen: isOpenOverride });
      const [consents, setConsents] = useState<Consents | null>(null);
      const { ppms } = getWindowDependencies();

      useShopifyConsentScript({
         piwikSiteId,
         shopifyConfig,
         needsConsent,
      });

      const closeBanner = () => {
         setShowDetails(false);
         setShowBanner(false);
         onClose();
      };

      const openBanner = useCallback(() => {
         onOpen();
         setShowBanner(true);
      }, [onOpen, setShowBanner]);

      useEffect(() => {
         if (!ppms) {
            return;
         }
         initializeConsentSettings(ppms, shopifyConfig, needsConsent, setConsents, openBanner);

         parseSavedConsentSettings(ppms, setConsents, openBanner);
      }, [ppms, openBanner, needsConsent, shopifyConfig]);

      return showBanner && consents && ppms ? (
         <ConsentBanner
            consents={consents}
            closeBanner={closeBanner}
            ppms={ppms}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
            CustomLink={CustomLink}
            privacyPolicyUrl={privacyPolicyUrl}
            shopifyConfig={shopifyConfig}
         />
      ) : null;
   }
);

function ConsentBanner({
   consents,
   closeBanner,
   ppms,
   showDetails,
   setShowDetails,
   CustomLink,
   privacyPolicyUrl,
   shopifyConfig,
}: {
   consents: Consents;
   closeBanner: () => void;
   ppms: ppmsType;
   showDetails: boolean;
   setShowDetails: (val: boolean) => void;
   CustomLink: FunctionComponent<PropsWithChildren<LinkProps>>;
   privacyPolicyUrl: string;
   shopifyConfig: ShopifyConfig;
}) {
   const t = useTranslations('ConsentBanner');
   useEffect(() => {
      setShowDetails(showDetails);
   }, [setShowDetails, showDetails]);

   function agreeToAll() {
      setStatusForAll(consents, ACCEPTED, shopifyConfig, closeBanner);
      ppms.cm.api('trackAgreeToAllClick');
   }

   function rejectAll() {
      setStatusForAll(consents, REJECTED, shopifyConfig, closeBanner);
      ppms.cm.api('trackRejectAllClick');
   }

   return (
      <Box
         data-testid="consent-banner"
         position="fixed"
         left={'50%'}
         bottom={{ base: 6, sm: 3 }}
         transform="translateX(-50%)"
         zIndex="overlay"
         fontSize="md"
         maxWidth="944px"
         width={{ base: 'calc(100vw - 38px)' }}
      >
         <Box mx="auto" p={6} background="white" borderWidth={1} borderRadius="lg" boxShadow="lg">
            <Stack
               mx="auto"
               direction={{ base: 'column', sm: 'row' }}
               alignItems={{ base: 'start', sm: 'center' }}
               spacing={{ base: 2, sm: 3 }}
            >
               <Text m={0} fontSize="inherit">
                  {t.rich('intro', {
                     link: (chunks: ReactNode) => (
                        <CustomLink href={privacyPolicyUrl}>{chunks}</CustomLink>
                     ),
                  })}
               </Text>
               {!showDetails && (
                  <ButtonGroup spacing={{ base: 2, sm: 3 }} size={{ base: 'xs', sm: 'sm' }}>
                     <SecondaryButton
                        onClick={() => setShowDetails(true)}
                        data-testid="details-button"
                     >
                        {t('buttons.details')}
                     </SecondaryButton>
                     <PrimaryButton onClick={agreeToAll} data-testid="accept-all-button">
                        {t('buttons.acceptAll')}
                     </PrimaryButton>
                  </ButtonGroup>
               )}
            </Stack>
            {showDetails && (
               <Details agreeToAll={agreeToAll} rejectAll={rejectAll} CustomLink={CustomLink} />
            )}
         </Box>
      </Box>
   );
}

function Details({
   agreeToAll,
   rejectAll,
   CustomLink,
}: {
   agreeToAll: () => void;
   rejectAll: () => void;
   CustomLink: FunctionComponent<PropsWithChildren<LinkProps>>;
}) {
   const t = useTranslations('ConsentBanner');
   return (
      <Box data-testid="details" mt={{ base: 2, sm: 3 }}>
         <ButtonGroup spacing={{ base: 2, sm: 3 }} size={{ base: 'xs', sm: 'sm' }}>
            <SecondaryButton onClick={rejectAll} data-testid="reject-all-button">
               {t('buttons.rejectAll')}
            </SecondaryButton>
            <PrimaryButton onClick={agreeToAll} data-testid="accept-all-button">
               {t('buttons.acceptAll')}
            </PrimaryButton>
         </ButtonGroup>
         <Stack gap={2} mt={{ base: 3, sm: 6 }}>
            <Text m={0}>{t('details.heading')}</Text>
            <UnorderedList m={0} mt={1} pl={6}>
               <ListItem>{t('details.firstBullet')}</ListItem>
               <ListItem>{t('details.secondBullet')}</ListItem>
               <ListItem>{t('details.thirdBullet')}</ListItem>
            </UnorderedList>
            <Text m={0}>
               {t.rich('details.subtext', {
                  link: (chunks: ReactNode) => (
                     <CustomLink href={'https://business.safety.google/privacy/'}>
                        {chunks}
                     </CustomLink>
                  ),
               })}
            </Text>
         </Stack>
      </Box>
   );
}

const SecondaryButton = ({ children, ...buttonProps }: { children: ReactNode } & ButtonProps) => (
   <Button color="black" fontSize="inherit" fontWeight="600" background="gray.200" {...buttonProps}>
      {children}
   </Button>
);

const PrimaryButton = ({ children, ...buttonProps }: { children: ReactNode } & ButtonProps) => (
   <Button variant="cta" fontSize="inherit" {...buttonProps}>
      {children}
   </Button>
);

function initializeConsentSettings(
   ppms: ppmsType,
   shopifyConfig: ShopifyConfig,
   needsConsent: boolean,
   setConsents: (_consents: Consents) => void,
   openBanner: () => void
) {
   ppms.cm.api('getNewComplianceTypes', (newConsentTypes: ConsentType[]) => {
      if (newConsentTypes.length === 0) {
         return null;
      }
      ppms.cm.api('setInitialComplianceSettings', { consents: newConsentTypes }, () => {
         const defaultConsents = getDefaultConsents(newConsentTypes);
         if (needsConsent) {
            ppms.cm.api('trackMainFormView');
            setConsents(defaultConsents);
            openBanner();
         } else {
            setStatusForAll(
               defaultConsents,
               ACCEPTED,
               shopifyConfig,
               () => {} // do not close banner since banner is not open
            );
            setConsents(defaultConsents);
         }
      });
   });
}

function parseSavedConsentSettings(
   ppms: ppmsType,
   setConsents: (_consents: Consents) => void,
   openBanner: () => void
) {
   ppms.cm.api('getComplianceSettings', (complianceSettings: { consents: Consents }) => {
      const savedConsents = complianceSettings.consents;
      setConsents(savedConsents);
      for (const consentType in savedConsents) {
         const consent = savedConsents[consentType as ConsentType];
         if (consent.status === NOT_SEEN) {
            openBanner();
            return;
         }
      }
   });
}

function setStatusForAll(
   consents: Consents,
   status: number,
   shopifyConfig: ShopifyConfig,
   successCallback: () => unknown
) {
   const { ppms, shopify } = getWindowDependencies();
   if (!ppms) {
      throw new SentryError('Tried setting consents without Piwik');
   }
   for (const consentType in consents) {
      consents[consentType as ConsentType].status = status;
   }
   ppms.cm.api('setComplianceSettings', { consents }, successCallback);
   setGA4Consent({ consent: status === 1, update: true });
   const shopifyConsentValue = status === 1 ? true : false;
   const shopifyApi = shopify?.customerPrivacy;
   shopifyApi?.setTrackingConsent({
      'marketing': shopifyConsentValue,
      'analytics': shopifyConsentValue,
      'preferences': shopifyConsentValue,
      'sale_of_data': shopifyConsentValue,
      'headlessStorefront': true,
      'checkoutRootDomain': shopifyConfig.checkout,
      'storefrontRootDomain': shopifyConfig.storefront,
      'storefrontAccessToken': shopifyConfig.accessToken,
   });
}

function getDefaultConsents(consentTypes: string[]): Consents {
   const consents = {} as Consents;
   consentTypes.forEach(consentType => {
      consents[consentType as ConsentType] = { status: NOT_SEEN };
   });
   return consents;
}

function getWindowDependencies() {
   if (typeof window === 'undefined') {
      return { ppms: null, shopify: null };
   }
   const shopify = window?.Shopify ?? null;
   const ppms = window?.ppms?.cm?.api ? window.ppms : null;
   return { ppms, shopify };
}
