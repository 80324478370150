import { CustomAnalyticsEvent } from '..';
import { piwikPush } from './piwikPush';

/**
 * @example
 * const TrackingBrochureDownloads = {
 *   eventCategory: 'Downloads',
 *   eventAction: 'PDF Brochure Download',
 *   eventName: 'MyBrochure'
 * }
 * @example
 * const TrackingUserReviews = {
 *   eventCategory: 'Reviews'
 *   eventAction: 'Published Piwik Review'
 *   eventValue: 10
 * }
 */
export const trackInPiwik = (trackData: CustomAnalyticsEvent) => {
   const dataWithEventName = {
      ...trackData,
      eventName: trackData.eventName || `${window.location.origin}${window.location.pathname}`,
   };
   piwikPush([
      'trackEvent',
      dataWithEventName.eventCategory,
      dataWithEventName.eventAction,
      dataWithEventName.eventName,
      dataWithEventName.eventValue,
   ]);
};
