import { getVariantIdFromVariantURI } from '@ifixit/helpers';
import type { CartLineItem } from '@ifixit/shopify-cart-sdk';
import {
   trackPiwikV2AddToCart,
   trackPiwikV2ProductDetailView,
   trackPiwikV2RemoveFromCart,
} from './piwik';
import { trackInPiwik } from './piwik/track-event';
import {
   trackCustomGAEvent,
   trackGA4AddToCart,
   trackGA4RemoveFromCart,
   trackGA4ViewItem,
   trackGA4GrowthbookEvent,
} from './google';
import { trackClarityEvent, trackClarityTag } from './clarity';

export * from './piwik';
export * from './google';

export type AnalyticsItem = {
   item_id: string | null | undefined;
   item_name: string | null;
   item_variant: string | null;
   quantity: number | null | undefined;
   price: number;
   categories?: string[];
   fulfiller?: string;
   oemPartnership?: string;
};

export type AnalyticsItemsEvent = {
   items: AnalyticsItem[];
   value: number;
   currency: string;
   localeCode?: string;
};

export type CustomAnalyticsEvent = {
   /**
    * Describes the type of events you want to track.
    * For example, Link Clicks, Videos, Outbound Links, and Form Events.
    */
   eventCategory: string;
   /**
    * The specific action that is taken.
    * For example, with a Video category, you might have a Play, Pause and Complete action.
    */
   eventAction: string;
   /**
    * Usually the title of the element that is being interacted with, to aid with analysis.
    * For example, it could be the name of a Video that was played or the specific
    * form that is being submitted.
    */
   eventName?: string;
   /**
    * A numeric value and is often added dynamically. It could be the cost of a
    * product that is added to a cart, or the completion percentage of a video.
    */
   eventValue?: number;
};

export type GrowthbookEvent = {
   experimentId: string;
   variationId: string;
};

export function trackInAnalyticsViewItem(event: AnalyticsItemsEvent) {
   trackGA4ViewItem(event);
   trackPiwikV2ProductDetailView(event);
   trackClarityEvent('productDetailView');
}

export function trackInAnalyticsAddToCart(event: AnalyticsItemsEvent) {
   trackGA4AddToCart(event);
   trackPiwikV2AddToCart(event);
   trackClarityEvent('addToCart');
}

export function trackInAnalyticsRemoveFromCart(event: AnalyticsItemsEvent) {
   trackGA4RemoveFromCart(event);
   trackPiwikV2RemoveFromCart(event);
   trackClarityEvent('removeFromCart');
}

export function convertCartLineItemsToAnalyticsItem(items: CartLineItem[]): AnalyticsItem[] {
   return items.map(item => ({
      item_id: item.itemcode,
      item_name: item.internalDisplayName ?? `${item.name} - ${item.variantTitle}`,
      item_variant: getVariantIdFromVariantURI(item.shopifyVariantId),
      quantity: item.quantity,
      price: Number(item.price.amount),
      categories: item.categories ?? [],
      fulfiller: item.fulfiller,
      oemPartnership: item.oemPartnership?.code,
   }));
}

export function getCategoriesFromAPICategories(apiCategories: {
   is_tool: boolean;
   item_category: string;
   part_subcategory: string;
}): string[] {
   const firstCategory = apiCategories.is_tool ? 'Tool' : 'Part';
   return [firstCategory, apiCategories.item_category, apiCategories.part_subcategory];
}

export function trackAnalyticsCustomEvent(props: CustomAnalyticsEvent) {
   trackCustomGAEvent(props);
   trackInPiwik(props);
   trackClarityEvent(props.eventCategory);
   const clarityAction = props.eventAction.replace(`${props.eventCategory} - `, '');
   trackClarityTag(props.eventCategory, clarityAction);
}

export function trackAnalyticsGrowthbookEvent({ variationId, experimentId }: GrowthbookEvent) {
   trackGA4GrowthbookEvent({
      eventName: 'experiment_viewed',
      experimentId,
      variationId,
   });

   trackInPiwik({
      eventCategory: 'Experiment Viewed',
      eventAction: variationId,
      eventName: experimentId,
   });
}
