declare global {
   interface Window {
      clarity: {
         (event: 'event', action: string): void;
         (event: 'set', key: string, value: string): void;
      };
   }
}

export function trackClarityEvent(action: string) {
   if (typeof window === 'undefined' || window.clarity === undefined) {
      return;
   }

   window.clarity('event', action);
}

export function trackClarityTag(key: string, value: string) {
   if (typeof window === 'undefined' || window.clarity === undefined) {
      return;
   }

   window.clarity('set', key, value);
}
