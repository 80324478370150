import { getShopifyLanguageFromCurrentURL, isKeyOf } from '@ifixit/helpers';
import { AnalyticsItem, AnalyticsItemsEvent } from '..';
import { getPiwikSiteId, piwikPush } from './piwikPush';

type PiwikCustomDimensions = {
   preferredStore: number;
   preferredLanguage: number;
   userPrivilege: number;
   visitedStore: number;
   oemPartnership: number;
   pageBreadcrumbs: number;
   featuredProducts: number;
   localeCode: number;
   shownConsentBanner: number;
};

export function trackPiwikPreferredStore(storeDomain: string): void {
   if (typeof window === 'undefined') return;
   const customDimensions = getPiwikCustomDimensions();
   if (!customDimensions) return;
   piwikPush(['setCustomDimensionValue', customDimensions['preferredStore'], storeDomain]);
}

export function trackPiwikPreferredLanguage(preferredLang: string | null): void {
   if (typeof window === 'undefined') return;
   const customDimensions = getPiwikCustomDimensions();
   if (!customDimensions) return;
   const lang = preferredLang?.toUpperCase() ?? getShopifyLanguageFromCurrentURL();
   piwikPush(['setCustomDimensionValue', customDimensions['preferredLanguage'], lang]);
}

export function trackPiwikUserPrivilege(userPrivilege: string | null): void {
   if (typeof window === 'undefined') return;
   const customDimensions = getPiwikCustomDimensions();
   if (!customDimensions) return;
   piwikPush(['setCustomDimensionValue', customDimensions['userPrivilege'], userPrivilege]);
}

export function trackVisitedStore(): void {
   if (typeof window === 'undefined') return;
   const customDimensions = getPiwikCustomDimensions();
   if (!customDimensions) return;
   piwikPush(['setCustomDimensionValue', customDimensions['visitedStore'], true]);
   piwikPush(['ping']);
}

export function trackOEMPartnershipEvent(oemPartnership: string | undefined): void {
   if (typeof window === 'undefined') return;
   const customDimensions = getPiwikCustomDimensions();
   if (!customDimensions) return;
   piwikPush(['setCustomDimensionValue', customDimensions['oemPartnership'], oemPartnership]);
}

export function trackPiwikFeaturedProducts(productSKUs: string[]): void {
   if (typeof window === 'undefined') return;
   const customDimensions = getPiwikCustomDimensions();
   if (!customDimensions) return;
   piwikPush(['setCustomDimensionValue', customDimensions['featuredProducts'], productSKUs]);
}

export function trackPiwikConsentBanner(shownConsentBanner: boolean) {
   if (typeof window === 'undefined') return;
   const customDimensions = getPiwikCustomDimensions();
   if (!customDimensions) return;
   piwikPush([
      'setCustomDimensionValue',
      customDimensions['shownConsentBanner'],
      shownConsentBanner,
   ]);
}

export function trackPageBreadcrumbEvent(
   breadcrumbs: {
      label: string;
      url?: string;
   }[]
): void {
   const customDimensions = getPiwikCustomDimensions();
   if (typeof window !== 'undefined' && customDimensions && breadcrumbs.length >= 2) {
      if (
         !breadcrumbs[breadcrumbs.length - 1]?.url ||
         breadcrumbs[breadcrumbs.length - 1]?.url === '#'
      ) {
         breadcrumbs[breadcrumbs.length - 1].url = window.location.pathname;
      }
      const pageBreadcrumbs =
         ':' +
         breadcrumbs
            .map(breadcrumb => decodeURIComponent(breadcrumb.url?.split('/').pop() ?? ''))
            .join(':') +
         ':';
      piwikPush(['setCustomDimensionValue', customDimensions['pageBreadcrumbs'], pageBreadcrumbs]);
   }
}

export function trackPiwikV2ProductDetailView(
   event: AnalyticsItemsEvent,
   shouldClearEventDimensions: boolean = false
) {
   handleEventDimensions(event);
   piwikPush([
      'ecommerceProductDetailView',
      event.items.map(item => formatProduct(item, event.currency)),
   ]);
   if (shouldClearEventDimensions) {
      clearEventDimensions(event);
   }
}

export function trackPiwikV2AddToCart(
   event: AnalyticsItemsEvent,
   shouldClearEventDimensions: boolean = false
) {
   handleEventDimensions(event);
   piwikPush(['ecommerceAddToCart', event.items.map(item => formatProduct(item, event.currency))]);
   if (shouldClearEventDimensions) {
      clearEventDimensions(event);
   }
}

export function trackPiwikV2RemoveFromCart(
   event: AnalyticsItemsEvent,
   shouldClearEventDimensions: boolean = false
) {
   handleEventDimensions(event);
   piwikPush([
      'ecommerceRemoveFromCart',
      event.items.map(item => formatProduct(item, event.currency)),
   ]);
   if (shouldClearEventDimensions) {
      clearEventDimensions(event);
   }
}

export function trackPiwikCartUpdate(
   event: AnalyticsItemsEvent,
   shouldClearEventDimensions: boolean = false
) {
   handleEventDimensions(event);
   piwikPush([
      'ecommerceCartUpdate',
      event.items.map(item => formatProduct(item, event.currency)),
      event.value.toString(),
   ]);
   if (shouldClearEventDimensions) {
      clearEventDimensions(event);
   }
}

export function clearPiwikCustomDimensions(dimensions: string[]) {
   const customDimensions = getPiwikCustomDimensions();
   if (customDimensions) {
      dimensions.forEach(element => {
         if (isKeyOf(element, customDimensions)) {
            piwikPush(['deleteCustomDimension', customDimensions[element]]);
         }
      });
   }
}

export function setPiwikPageTitle() {
   if (typeof document !== 'undefined') {
      piwikPush(['setDocumentTitle', document.title]);
   }
}

function getPiwikCustomDimensions(): PiwikCustomDimensions | null {
   const siteId = getPiwikSiteId();
   switch (siteId) {
      // prod
      case '6bf6bc54-82ca-4321-8620-b12d5c9b57b6': {
         return {
            preferredStore: 1,
            preferredLanguage: 2,
            userPrivilege: 3,
            visitedStore: 6,
            oemPartnership: 8,
            pageBreadcrumbs: 10,
            featuredProducts: 11,
            shownConsentBanner: 20,
            localeCode: 21,
         };
      }
      // dev
      case '748e3baf-bedc-4685-9f32-6b72ec349fd4': {
         return {
            preferredStore: 1,
            preferredLanguage: 2,
            userPrivilege: 3,
            visitedStore: 4,
            oemPartnership: 6,
            pageBreadcrumbs: 9,
            featuredProducts: 10,
            shownConsentBanner: 13,
            localeCode: 14,
         };
      }
      default: {
         return null;
      }
   }
}

function formatProduct(item: AnalyticsItem, currencyCode: string) {
   const currency = isKeyOf(currencyCode, currencyConversions) ? currencyCode : 'USD';
   return {
      sku: item.item_id,
      name: item.item_name,
      price: toUSD(item.price, currency).toString(),
      quantity: item.quantity,
      variant: item.item_variant,
      category: item.categories,
      customDimensions: {
         '1': item.fulfiller,
         '2': item.oemPartnership,
      },
   };
}

function handleEventDimensions(event: AnalyticsItemsEvent) {
   if (typeof window === 'undefined') return;
   const customDimensions = getPiwikCustomDimensions();
   if (!customDimensions) return;
   Object.entries(event).forEach(([key, value]) => {
      if (isKeyOf(key, customDimensions)) {
         piwikPush(['setCustomDimensionValue', customDimensions[key], value]);
      }
   });
}

function clearEventDimensions(event: AnalyticsItemsEvent) {
   const customDimensions = getPiwikCustomDimensions();
   if (!customDimensions) return;
   const eventDimensions = Object.keys(event).filter((key: string) =>
      isKeyOf(key, customDimensions)
   );
   clearPiwikCustomDimensions(eventDimensions);
}

const currencyConversions = {
   AUD: 0.75,
   CAD: 0.815,
   EUR: 1.15,
   GBP: 1.3,
   USD: 1,
};

export type CurrencyCode = keyof typeof currencyConversions;

export function toUSD(price: number, currencyCode: CurrencyCode) {
   const currencyConversionRatio = currencyConversions[currencyCode] ?? 1;
   return price * currencyConversionRatio;
}
